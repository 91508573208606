export const ESNSections = [
  { section: 'ESN Tirana', country: 'ESN Albania' },
  { section: 'ESN BFI Vienna', country: 'ESN Austria' },
  { section: 'ESN BOKU Wien', country: 'ESN Austria' },
  { section: 'ESN Buddynetwork TU Wien', country: 'ESN Austria' },
  { section: 'ESN FH Wien WKW', country: 'ESN Austria' },
  { section: 'ESN Innsbruck', country: 'ESN Austria' },
  { section: 'ESN Klagenfurt', country: 'ESN Austria' },
  { section: 'ESN Krems', country: 'ESN Austria' },
  { section: 'ESN Kufstein', country: 'ESN Austria' },
  { section: 'ESN Leoben', country: 'ESN Austria' },
  { section: 'ESN Linz', country: 'ESN Austria' },
  { section: 'ESN Technikum Wien', country: 'ESN Austria' },
  { section: 'ESN TU Graz', country: 'ESN Austria' },
  { section: 'ESN Uni Graz', country: 'ESN Austria' },
  { section: 'ESN Uni Salzburg', country: 'ESN Austria' },
  { section: 'ESN Uni Wien', country: 'ESN Austria' },
  { section: 'ESN ADA Baku', country: 'ESN Azerbaijan' },
  { section: 'ESN YKSUG Grodno', country: 'ESN Belarus' },
  { section: 'ESN Antwerp', country: 'ESN Belgium' },
  { section: 'ESN Gent', country: 'ESN Belgium' },
  { section: 'ESN Hasselt', country: 'ESN Belgium' },
  { section: 'ESN HEC Liege', country: 'ESN Belgium' },
  { section: 'ESN HELMo', country: 'ESN Belgium' },
  { section: 'ESN HEPL', country: 'ESN Belgium' },
  { section: 'ESN ICHEC ECAM Brussels', country: 'ESN Belgium' },
  { section: 'ESN KULBrussels', country: 'ESN Belgium' },
  { section: 'ESN Leonardo Kortrijk', country: 'ESN Belgium' },
  { section: 'ESN Leuven', country: 'ESN Belgium' },
  { section: 'ESN Louvain-la-Neuve', country: 'ESN Belgium' },
  { section: 'ESN Mechelen', country: 'ESN Belgium' },
  { section: 'ESN Mons', country: 'ESN Belgium' },
  { section: 'ESN Saint-Louis Brussels', country: 'ESN Belgium' },
  { section: 'ESN ULB Brussels', country: 'ESN Belgium' },
  { section: 'ESN ULiège', country: 'ESN Belgium' },
  { section: 'ESN VUB EhB Brussels', country: 'ESN Belgium' },
  { section: 'ESN Mostar', country: 'ESN Bosnia and Herzegovina' },
  { section: 'ESN Sarajevo', country: 'ESN Bosnia and Herzegovina' },
  { section: 'ESN - New Bulgarian University', country: 'ESN Bulgaria' },
  { section: 'ESN AUBG', country: 'ESN Bulgaria' },
  { section: 'ESN Plovdiv', country: 'ESN Bulgaria' },
  { section: 'ESN Sofia University', country: 'ESN Bulgaria' },
  { section: 'ESN Svishtov', country: 'ESN Bulgaria' },
  { section: 'ESN UACEG', country: 'ESN Bulgaria' },
  { section: 'ESN UCTM', country: 'ESN Bulgaria' },
  { section: 'ESN UNWE', country: 'ESN Bulgaria' },
  { section: 'ESN UR', country: 'ESN Bulgaria' },
  { section: 'ESN Varna', country: 'ESN Bulgaria' },
  { section: 'ESN Veliko Tarnovo', country: 'ESN Bulgaria' },
  { section: 'ESN Čakovec', country: 'ESN Croatia' },
  { section: 'ESN Dubrovnik', country: 'ESN Croatia' },
  { section: 'ESN Osijek', country: 'ESN Croatia' },
  { section: 'ESN Rijeka', country: 'ESN Croatia' },
  { section: 'ESN Split', country: 'ESN Croatia' },
  { section: 'ESN Zadar', country: 'ESN Croatia' },
  { section: 'ESN Zagreb', country: 'ESN Croatia' },
  { section: 'ESN Nicosia', country: 'ESN Cyprus' },
  { section: 'ESN CU Prague', country: 'ESN Czech Republic' },
  { section: 'ESN CZU Prague', country: 'ESN Czech Republic' },
  { section: 'ESN Hradec Kralove', country: 'ESN Czech Republic' },
  { section: 'ESN Liberec', country: 'ESN Czech Republic' },
  { section: 'ESN MENDELU Brno', country: 'ESN Czech Republic' },
  { section: 'ESN MUNI Brno', country: 'ESN Czech Republic' },
  { section: 'ESN Ostravská', country: 'ESN Czech Republic' },
  { section: 'ESN Pardubice', country: 'ESN Czech Republic' },
  { section: 'ESN UCT Prague', country: 'ESN Czech Republic' },
  { section: 'ESN UP OLOMOUC', country: 'ESN Czech Republic' },
  { section: 'ESN USB Budweis', country: 'ESN Czech Republic' },
  { section: 'ESN Usti', country: 'ESN Czech Republic' },
  { section: 'ESN VSB - TU Ostrava', country: 'ESN Czech Republic' },
  { section: 'ESN VSE Prague', country: 'ESN Czech Republic' },
  { section: 'ESN VSTE Budweis', country: 'ESN Czech Republic' },
  { section: 'ESN VUT Brno', country: 'ESN Czech Republic' },
  { section: 'ESN Zlin', country: 'ESN Czech Republic' },
  { section: 'ISC CTU in Prague', country: 'ESN Czech Republic' },
  { section: 'ESN Aarhus', country: 'ESN Denmark' },
  { section: 'ESN Copenhagen', country: 'ESN Denmark' },
  { section: 'ESN DTU', country: 'ESN Denmark' },
  { section: 'ESN Haderslev', country: 'ESN Denmark' },
  { section: 'ESN Kolding', country: 'ESN Denmark' },
  { section: 'ESN Odense', country: 'ESN Denmark' },
  { section: 'ESN EBS', country: 'ESN Estonia' },
  { section: 'ESN Tallinn', country: 'ESN Estonia' },
  { section: 'ESN TalTech IC', country: 'ESN Estonia' },
  { section: 'ESN Tartu', country: 'ESN Estonia' },
  { section: 'ESN TU IC', country: 'ESN Estonia' },
  { section: 'ESN Aalto', country: 'ESN Finland' },
  { section: 'ESN Åbo Akademi', country: 'ESN Finland' },
  { section: 'ESN FINT', country: 'ESN Finland' },
  { section: 'ESN Helga', country: 'ESN Finland' },
  { section: 'ESN INTO Tampere', country: 'ESN Finland' },
  { section: 'ESN Joensuu', country: 'ESN Finland' },
  { section: 'ESN Jyväskylä', country: 'ESN Finland' },
  { section: 'ESN KISA', country: 'ESN Finland' },
  { section: 'ESN Lapland', country: 'ESN Finland' },
  { section: 'ESN Lappeenranta', country: 'ESN Finland' },
  { section: 'ESN LAUREA', country: 'ESN Finland' },
  { section: 'ESN Metropolia', country: 'ESN Finland' },
  { section: 'ESN Oulu', country: 'ESN Finland' },
  { section: 'ESN Uni Helsinki', country: 'ESN Finland' },
  { section: 'ESN Uni Turku', country: 'ESN Finland' },
  { section: 'ESN Vaasa', country: 'ESN Finland' },
  { section: 'ASSOCIU ERASMUS DI A CORSICA', country: 'ESN France' },
  { section: 'Autour du Monde - ESN Nantes', country: 'ESN France' },
  { section: 'ESN - COMPIEGNE UTC', country: 'ESN France' },
  { section: 'ESN Aix-En-Provence', country: 'ESN France' },
  { section: 'ESN Amiens', country: 'ESN France' },
  { section: 'ESN Belfort-Montbeliard', country: 'ESN France' },
  { section: 'ESN Besançon', country: 'ESN France' },
  { section: 'ESN BISE - Saint-Etienne', country: 'ESN France' },
  { section: 'ESN Bordeaux', country: 'ESN France' },
  { section: 'ESN De Vinci', country: 'ESN France' },
  { section: 'ESN Dijon', country: 'ESN France' },
  { section: 'ESN EDC', country: 'ESN France' },
  { section: 'ESN EPF', country: 'ESN France' },
  { section: 'ESN ESC Troyes', country: 'ESN France' },
  { section: 'ESN Globe and Co Nancy', country: 'ESN France' },
  { section: 'ESN La Rochelle', country: 'ESN France' },
  { section: 'ESN Le Mans', country: 'ESN France' },
  { section: 'ESN Lille', country: 'ESN France' },
  { section: 'ESN Lyon', country: 'ESN France' },
  { section: 'ESN Marseille', country: 'ESN France' },
  { section: 'ESN Montpellier', country: 'ESN France' },
  { section: 'ESN Nancy', country: 'ESN France' },
  { section: 'ESN Nice', country: 'ESN France' },
  { section: 'ESN Orleans', country: 'ESN France' },
  { section: 'ESN Pau', country: 'ESN France' },
  { section: 'ESN Poitiers', country: 'ESN France' },
  { section: 'ESN Reims', country: 'ESN France' },
  { section: 'ESN Rennes', country: 'ESN France' },
  { section: 'ESN Rouen', country: 'ESN France' },
  { section: 'ESN Strasbourg', country: 'ESN France' },
  { section: 'ESN Télécom Bretagne', country: 'ESN France' },
  { section: 'ESN Toulouse', country: 'ESN France' },
  { section: 'ESN Tours', country: 'ESN France' },
  { section: 'ESN VALENCE', country: 'ESN France' },
  { section: 'ESN Valenciennes', country: 'ESN France' },
  { section: 'ESN Worldtop Clermont-Ferrand', country: 'ESN France' },
  { section: 'ESN-Paris', country: 'ESN France' },
  { section: 'ESN Tbilisi ISU', country: 'ESN Georgia' },
  { section: 'ESN Augsburg', country: 'ESN Germany' },
  { section: 'ESN Bayreuth', country: 'ESN Germany' },
  { section: 'ESN Bochum', country: 'ESN Germany' },
  { section: 'ESN Bonn e.V.', country: 'ESN Germany' },
  { section: 'ESN Braunschweig', country: 'ESN Germany' },
  { section: 'ESN Darmstadt', country: 'ESN Germany' },
  { section: 'ESN Deggendorf', country: 'ESN Germany' },
  { section: 'ESN Dortmund', country: 'ESN Germany' },
  { section: 'ESN Düsseldorf', country: 'ESN Germany' },
  { section: 'ESN Erasmix Medizin Berlin', country: 'ESN Germany' },
  { section: 'ESN Frankfurt (Oder)', country: 'ESN Germany' },
  { section: 'ESN Frankfurt am Main', country: 'ESN Germany' },
  { section: 'ESN Freiburg e.V.', country: 'ESN Germany' },
  { section: 'ESN Göttingen', country: 'ESN Germany' },
  { section: 'ESN Halle (Saale)', country: 'ESN Germany' },
  { section: 'ESN Hamburg', country: 'ESN Germany' },
  { section: 'ESN Hannover', country: 'ESN Germany' },
  { section: 'ESN Heidelberg', country: 'ESN Germany' },
  { section: 'ESN Hildesheim', country: 'ESN Germany' },
  { section: 'ESN HTW Dresden', country: 'ESN Germany' },
  { section: 'ESN Ingolstadt', country: 'ESN Germany' },
  { section: 'ESN IPAS Landau', country: 'ESN Germany' },
  { section: 'ESN Jena', country: 'ESN Germany' },
  { section: 'ESN Kaiserslautern', country: 'ESN Germany' },
  { section: 'ESN Karlsruhe', country: 'ESN Germany' },
  { section: 'ESN Kiel', country: 'ESN Germany' },
  { section: 'ESN Koblenz', country: 'ESN Germany' },
  { section: 'ESN Köln', country: 'ESN Germany' },
  { section: 'ESN Konstanz', country: 'ESN Germany' },
  { section: 'ESN LEI Greifswald', country: 'ESN Germany' },
  { section: 'ESN LEI Rostock', country: 'ESN Germany' },
  { section: 'ESN Mannheim', country: 'ESN Germany' },
  { section: 'ESN MESA München', country: 'ESN Germany' },
  { section: 'ESN Pforzheim', country: 'ESN Germany' },
  { section: 'ESN Potsdam', country: 'ESN Germany' },
  { section: 'ESN Saarbrücken', country: 'ESN Germany' },
  { section: 'ESN Siegen', country: 'ESN Germany' },
  { section: 'ESN Stuttgart', country: 'ESN Germany' },
  { section: 'ESN TU Dresden', country: 'ESN Germany' },
  { section: 'ESN TUMi München', country: 'ESN Germany' },
  { section: 'ESN Witten/Herdecke', country: 'ESN Germany' },
  { section: 'ESN Athens AUEB', country: 'ESN Greece' },
  { section: 'ESN AUA Athens', country: 'ESN Greece' },
  { section: 'ESN AUTH', country: 'ESN Greece' },
  { section: 'ESN DUTH', country: 'ESN Greece' },
  { section: 'ESN HARO', country: 'ESN Greece' },
  { section: 'ESN Hellenic Mediterranean University', country: 'ESN Greece' },
  { section: 'ESN IHU', country: 'ESN Greece' },
  { section: 'ESN Ioannina', country: 'ESN Greece' },
  { section: 'ESN KAPA Athens', country: 'ESN Greece' },
  { section: 'ESN NTUA Athens', country: 'ESN Greece' },
  { section: 'ESN Panteion', country: 'ESN Greece' },
  { section: 'ESN TUC', country: 'ESN Greece' },
  { section: 'ESN UniPi', country: 'ESN Greece' },
  { section: 'ESN UOC', country: 'ESN Greece' },
  { section: 'ESN UOM Thessaloniki', country: 'ESN Greece' },
  { section: 'ESN UOPA', country: 'ESN Greece' },
  { section: 'ESN UTH', country: 'ESN Greece' },
  { section: 'ESN West Attica', country: 'ESN Greece' },
  { section: 'ESN Western Macedonia', country: 'ESN Greece' },
  { section: 'ESN BME', country: 'ESN Hungary' },
  { section: 'ESN Corvinus', country: 'ESN Hungary' },
  { section: 'ESN Debrecen', country: 'ESN Hungary' },
  { section: 'ESN ELTE', country: 'ESN Hungary' },
  { section: 'ESN Eszterhazy', country: 'ESN Hungary' },
  { section: 'ESN HEALTH BP', country: 'ESN Hungary' },
  { section: 'ESN IBS', country: 'ESN Hungary' },
  { section: 'ESN MATE', country: 'ESN Hungary' },
  { section: 'ESN Miskolc', country: 'ESN Hungary' },
  { section: 'ESN Nyíregyháza', country: 'ESN Hungary' },
  { section: 'ESN Óbuda University', country: 'ESN Hungary' },
  { section: 'ESN Pécs', country: 'ESN Hungary' },
  { section: 'ESN Szeged', country: 'ESN Hungary' },
  { section: 'ESN UPS', country: 'ESN Hungary' },
  { section: 'ESN Haskoli Islands', country: 'ESN Iceland' },
  { section: 'ESN DKIT', country: 'ESN Ireland' },
  { section: 'ESN Maynooth', country: 'ESN Ireland' },
  { section: 'ESN UCD', country: 'ESN Ireland' },
  { section: 'I*ESN DCU', country: 'ESN Ireland' },
  { section: 'ESN Ancona', country: 'ESN Italy' },
  { section: 'ESN Perugia', country: 'ESN Italy' },
  { section: 'ESN ASE Verona', country: 'ESN Italy' },
  { section: 'ESN ASSI Parma', country: 'ESN Italy' },
  { section: 'ESN AURE Camerino', country: 'ESN Italy' },
  { section: 'ESN Bari', country: 'ESN Italy' },
  { section: 'ESN Bergamo', country: 'ESN Italy' },
  { section: 'ESN Bologna', country: 'ESN Italy' },
  { section: 'ESN Bolzano', country: 'ESN Italy' },
  { section: 'ESN Brescia', country: 'ESN Italy' },
  { section: 'ESN Cagliari', country: 'ESN Italy' },
  { section: 'ESN Catania', country: 'ESN Italy' },
  { section: 'ESN Chieti Pescara', country: 'ESN Italy' },
  { section: 'ESN Cosenza', country: 'ESN Italy' },
  { section: 'ESN Ferrara', country: 'ESN Italy' },
  { section: 'ESN Florentia', country: 'ESN Italy' },
  { section: 'ESN Foggia', country: 'ESN Italy' },
  { section: 'ESN GEG Genova', country: 'ESN Italy' },
  { section: 'ESN Insubria', country: 'ESN Italy' },
  { section: "ESN L' Aquila", country: 'ESN Italy' },
  { section: 'ESN Lecce', country: 'ESN Italy' },
  { section: 'ESN Macerata', country: 'ESN Italy' },
  { section: 'ESN Maleventum', country: 'ESN Italy' },
  { section: 'ESN Messina', country: 'ESN Italy' },
  { section: 'ESN Milano Bocconi', country: 'ESN Italy' },
  { section: 'ESN Milano IULM', country: 'ESN Italy' },
  { section: 'ESN Milano Statale', country: 'ESN Italy' },
  { section: 'ESN Milano Unicatt', country: 'ESN Italy' },
  { section: 'ESN Milano-Bicocca', country: 'ESN Italy' },
  { section: 'ESN Modena', country: 'ESN Italy' },
  { section: 'ESN Napoli', country: 'ESN Italy' },
  { section: 'ESN Padova', country: 'ESN Italy' },
  { section: 'ESN Palermo', country: 'ESN Italy' },
  { section: 'ESN Piemonte Orientale', country: 'ESN Italy' },
  { section: 'ESN Pisa', country: 'ESN Italy' },
  { section: 'ESN Politecnico Milano', country: 'ESN Italy' },
  { section: 'ESN Reggio Calabria', country: 'ESN Italy' },
  { section: 'ESN Rimini', country: 'ESN Italy' },
  { section: 'ESN Roma ASE', country: 'ESN Italy' },
  { section: 'ESN Roma LUISS', country: 'ESN Italy' },
  { section: 'ESN Roma Tre', country: 'ESN Italy' },
  { section: 'ESN Salerno', country: 'ESN Italy' },
  { section: 'ESN Sassari', country: 'ESN Italy' },
  { section: 'ESN Siena GES', country: 'ESN Italy' },
  { section: 'ESN STEP Pavia', country: 'ESN Italy' },
  { section: 'ESN Sui-Generis Basilicata', country: 'ESN Italy' },
  { section: 'ESN Teramo', country: 'ESN Italy' },
  { section: 'ESN Torino', country: 'ESN Italy' },
  { section: 'ESN Trento', country: 'ESN Italy' },
  { section: 'ESN Trieste', country: 'ESN Italy' },
  { section: 'ESN Udine', country: 'ESN Italy' },
  { section: 'ESN Unimol', country: 'ESN Italy' },
  { section: 'ESN Urbino', country: 'ESN Italy' },
  { section: 'ESN Venezia', country: 'ESN Italy' },
  { section: 'ESN Jelgava', country: 'ESN Latvia' },
  { section: 'ESN Riga', country: 'ESN Latvia' },
  { section: 'ESN Valmiera', country: 'ESN Latvia' },
  { section: 'ESN University of Liechtenstein', country: 'ESN Liechtenstein' },
  { section: 'ESN EHU', country: 'ESN Lithuania' },
  { section: 'ESN ISM', country: 'ESN Lithuania' },
  { section: 'ESN KK', country: 'ESN Lithuania' },
  { section: 'ESN KTU', country: 'ESN Lithuania' },
  { section: 'ESN KUK', country: 'ESN Lithuania' },
  { section: 'ESN LSMU', country: 'ESN Lithuania' },
  { section: 'ESN LSU', country: 'ESN Lithuania' },
  { section: 'ESN MRU Vilnius', country: 'ESN Lithuania' },
  { section: 'ESN ŠU', country: 'ESN Lithuania' },
  { section: 'ESN VGTU', country: 'ESN Lithuania' },
  { section: 'ESN VIKO', country: 'ESN Lithuania' },
  { section: 'ESN Vilnius University', country: 'ESN Lithuania' },
  { section: 'ESN VMU', country: 'ESN Lithuania' },
  { section: 'ESN Luxembourg', country: 'ESN Luxembourg' },
  { section: 'ESN Malta', country: 'ESN Malta' },
  { section: 'ESN Chisinau', country: 'ESN Moldova' },
  { section: 'ESN Ås', country: 'ESN Norway' },
  { section: 'ESN Bergen', country: 'ESN Norway' },
  { section: 'ESN Molde', country: 'ESN Norway' },
  { section: 'ESN Oslo', country: 'ESN Norway' },
  { section: 'ESN Stavanger', country: 'ESN Norway' },
  { section: 'ESN Tromsø', country: 'ESN Norway' },
  { section: 'ESN Trondheim', country: 'ESN Norway' },
  { section: 'ESN UiA', country: 'ESN Norway' },
  { section: 'ESN Szczecin', country: 'ESN Poland' },
  { section: 'ESN AGH Cracow', country: 'ESN Poland' },
  { section: 'ESN Gdansk (Politechnika)', country: 'ESN Poland' },
  { section: 'ESN LUT', country: 'ESN Poland' },
  { section: 'ESN Olsztyn', country: 'ESN Poland' },
  { section: 'ESN PB Bialystok', country: 'ESN Poland' },
  { section: 'ESN PK Cracow', country: 'ESN Poland' },
  { section: 'ESN Poznan', country: 'ESN Poland' },
  { section: 'ESN PW Warsaw', country: 'ESN Poland' },
  { section: 'ESN PWr', country: 'ESN Poland' },
  { section: 'ESN SGGW Warsaw', country: 'ESN Poland' },
  { section: 'ESN SGH Warsaw', country: 'ESN Poland' },
  { section: 'ESN SWPS University', country: 'ESN Poland' },
  { section: 'ESN Toruń', country: 'ESN Poland' },
  { section: 'ESN UAM Poznan', country: 'ESN Poland' },
  { section: 'ESN UE Poznan', country: 'ESN Poland' },
  { section: 'ESN UE Wroclaw', country: 'ESN Poland' },
  { section: 'ESN UEK Cracow', country: 'ESN Poland' },
  { section: 'ESN UG Gdansk', country: 'ESN Poland' },
  { section: 'ESN UJ Cracow', country: 'ESN Poland' },
  { section: 'ESN UKSW Warsaw', country: 'ESN Poland' },
  { section: 'ESN UL Lodz', country: 'ESN Poland' },
  { section: 'ESN UMCS Lublin', country: 'ESN Poland' },
  { section: 'ESN UŚ Katowice', country: 'ESN Poland' },
  { section: 'ESN UW Warsaw', country: 'ESN Poland' },
  { section: 'ESN UwB Bialystok', country: 'ESN Poland' },
  { section: 'ESN WUM Warsaw', country: 'ESN Poland' },
  { section: 'ESN-EYE Lodz', country: 'ESN Poland' },
  { section: 'ESN Algarve', country: 'ESN Portugal' },
  { section: 'ESN Almada', country: 'ESN Portugal' },
  { section: 'ESN Aveiro', country: 'ESN Portugal' },
  { section: 'ESN Bragança', country: 'ESN Portugal' },
  { section: 'ESN Castelo Branco', country: 'ESN Portugal' },
  { section: 'ESN Coimbra', country: 'ESN Portugal' },
  { section: 'ESN Covilhã', country: 'ESN Portugal' },
  { section: 'ESN Évora', country: 'ESN Portugal' },
  { section: 'ESN Lisboa', country: 'ESN Portugal' },
  { section: 'ESN Madeira', country: 'ESN Portugal' },
  { section: 'ESN Minho', country: 'ESN Portugal' },
  { section: 'ESN Porto', country: 'ESN Portugal' },
  { section: 'ESN Tomar', country: 'ESN Portugal' },
  { section: 'ESN UTAD', country: 'ESN Portugal' },
  { section: 'ESN ALBA IULIA', country: 'ESN Romania' },
  { section: 'ESN ASE Bucharest', country: 'ESN Romania' },
  { section: 'ESN Bacău', country: 'ESN Romania' },
  { section: 'ESN Brasov', country: 'ESN Romania' },
  { section: 'ESN Cluj-Napoca', country: 'ESN Romania' },
  { section: 'ESN Constanta', country: 'ESN Romania' },
  { section: 'ESN Craiova', country: 'ESN Romania' },
  { section: 'ESN Iasi', country: 'ESN Romania' },
  { section: 'ESN Piteşti', country: 'ESN Romania' },
  { section: 'ESN Poli', country: 'ESN Romania' },
  { section: 'ESN Sibiu', country: 'ESN Romania' },
  { section: 'ESN Suceava', country: 'ESN Romania' },
  { section: 'ESN Targu Mures', country: 'ESN Romania' },
  { section: 'ESN Timisoara', country: 'ESN Romania' },
  { section: 'ESN UniBucharest', country: 'ESN Romania' },
  { section: 'ESN HSE University Moscow', country: 'ESN Russia' },
  { section: 'ESN HSE University St. Petersburg', country: 'ESN Russia' },
  { section: 'ESN SPbSU', country: 'ESN Russia' },
  { section: 'ESN St Petersburg SFTU', country: 'ESN Russia' },
  { section: 'ESN Ural Federal', country: 'ESN Russia' },
  { section: 'ESN BelUPgrade', country: 'ESN Serbia' },
  { section: 'ESN Nis', country: 'ESN Serbia' },
  { section: 'ESN Novi Sad', country: 'ESN Serbia' },
  { section: 'ESN Comenius University', country: 'ESN Slovakia' },
  { section: 'ESN EU Bratislava', country: 'ESN Slovakia' },
  { section: 'ESN Košice', country: 'ESN Slovakia' },
  { section: 'ESN Presov', country: 'ESN Slovakia' },
  { section: 'ESN STUBA', country: 'ESN Slovakia' },
  { section: 'ESN SUA', country: 'ESN Slovakia' },
  { section: 'ESN Trnava', country: 'ESN Slovakia' },
  { section: 'ESN UKF', country: 'ESN Slovakia' },
  { section: 'ESN UMB BB', country: 'ESN Slovakia' },
  { section: 'ESN UNIZA', country: 'ESN Slovakia' },
  { section: 'ESN Ljubljana', country: 'ESN Slovenia' },
  { section: 'ESN Ljubljana University', country: 'ESN Slovenia' },
  { section: 'ESN Maribor', country: 'ESN Slovenia' },
  { section: 'ESN UEx', country: 'ESN Spain' },
  { section: 'ESN Alcala', country: 'ESN Spain' },
  { section: 'ESN ALICANTE', country: 'ESN Spain' },
  { section: 'ESN Almeria', country: 'ESN Spain' },
  { section: 'ESN Baleares', country: 'ESN Spain' },
  { section: 'ESN Barcelona UB', country: 'ESN Spain' },
  { section: 'ESN Barcelona UPF', country: 'ESN Spain' },
  { section: 'ESN Bilbao', country: 'ESN Spain' },
  { section: 'ESN Cadiz', country: 'ESN Spain' },
  { section: 'ESN Cartagena', country: 'ESN Spain' },
  { section: 'ESN Castellón', country: 'ESN Spain' },
  { section: 'ESN Córdoba', country: 'ESN Spain' },
  { section: 'ESN Coruna', country: 'ESN Spain' },
  { section: 'ESN en UV', country: 'ESN Spain' },
  { section: 'ESN Girona', country: 'ESN Spain' },
  { section: 'ESN Granada', country: 'ESN Spain' },
  { section: 'ESN Huelva', country: 'ESN Spain' },
  { section: 'ESN Jaén', country: 'ESN Spain' },
  { section: 'ESN Madrid UPM', country: 'ESN Spain' },
  { section: 'ESN Málaga', country: 'ESN Spain' },
  { section: 'ESN Murcia', country: 'ESN Spain' },
  { section: 'ESN Oviedo', country: 'ESN Spain' },
  { section: 'ESN Salamanca', country: 'ESN Spain' },
  { section: 'ESN Santander', country: 'ESN Spain' },
  { section: 'ESN Santiago de Compostela', country: 'ESN Spain' },
  { section: 'ESN Sevilla', country: 'ESN Spain' },
  { section: 'ESN UAB Barcelona', country: 'ESN Spain' },
  { section: 'ESN UAM Madrid', country: 'ESN Spain' },
  { section: 'ESN UAX', country: 'ESN Spain' },
  { section: 'ESN UC3M', country: 'ESN Spain' },
  { section: 'ESN UCLM', country: 'ESN Spain' },
  { section: 'ESN UCM Madrid', country: 'ESN Spain' },
  { section: 'ESN UCV', country: 'ESN Spain' },
  { section: 'ESN UPC Barcelona', country: 'ESN Spain' },
  { section: 'ESN UPO', country: 'ESN Spain' },
  { section: 'ESN URJC', country: 'ESN Spain' },
  { section: 'ESN Valencia UPV', country: 'ESN Spain' },
  { section: 'ESN Valladolid', country: 'ESN Spain' },
  { section: 'ESN Vigo', country: 'ESN Spain' },
  { section: 'ESN Boras', country: 'ESN Sweden' },
  { section: 'ESN BTH', country: 'ESN Sweden' },
  { section: 'ESN Göteborg', country: 'ESN Sweden' },
  { section: 'ESN Jönköping', country: 'ESN Sweden' },
  { section: 'ESN Kalmar', country: 'ESN Sweden' },
  { section: 'ESN Karlstad', country: 'ESN Sweden' },
  { section: 'ESN Linköping', country: 'ESN Sweden' },
  { section: 'ESN Lund', country: 'ESN Sweden' },
  { section: 'ESN Malmö', country: 'ESN Sweden' },
  { section: 'ESN Örebro', country: 'ESN Sweden' },
  { section: 'ESN Skövde', country: 'ESN Sweden' },
  { section: 'ESN Södertörn', country: 'ESN Sweden' },
  { section: 'ESN Stockholm', country: 'ESN Sweden' },
  { section: 'ESN Växjö', country: 'ESN Sweden' },
  { section: 'ESN-Luleå', country: 'ESN Sweden' },
  { section: 'ESN Basel', country: 'ESN Switzerland' },
  { section: 'ESN Bern', country: 'ESN Switzerland' },
  { section: 'ESN Chur', country: 'ESN Switzerland' },
  { section: 'ESN EPF Lausanne', country: 'ESN Switzerland' },
  { section: 'ESN FHNW', country: 'ESN Switzerland' },
  { section: 'ESN Fribourg', country: 'ESN Switzerland' },
  { section: 'ESN Genève', country: 'ESN Switzerland' },
  { section: 'ESN Lugano', country: 'ESN Switzerland' },
  { section: 'ESN Luzern', country: 'ESN Switzerland' },
  { section: 'ESN Neuchâtel', country: 'ESN Switzerland' },
  { section: 'ESN St Gallen', country: 'ESN Switzerland' },
  { section: 'ESN Uni Lausanne', country: 'ESN Switzerland' },
  { section: 'ESN Winterthur', country: 'ESN Switzerland' },
  { section: 'ESN Zurich', country: 'ESN Switzerland' },
  { section: 'ESN The Hague', country: 'ESN the Netherlands' },
  { section: 'ESN Amsterdam', country: 'ESN the Netherlands' },
  { section: 'ESN Breda', country: 'ESN the Netherlands' },
  { section: 'ESN Delft', country: 'ESN the Netherlands' },
  { section: 'ESN Groningen', country: 'ESN the Netherlands' },
  { section: 'ESN INHolland Diemen', country: 'ESN the Netherlands' },
  { section: 'ESN INHolland Haarlem', country: 'ESN the Netherlands' },
  { section: 'ESN Inholland Rotterdam', country: 'ESN the Netherlands' },
  { section: 'ESN Leeuwarden', country: 'ESN the Netherlands' },
  { section: 'ESN Leiden', country: 'ESN the Netherlands' },
  { section: 'ESN Maastricht', country: 'ESN the Netherlands' },
  { section: 'ESN Nijmegen', country: 'ESN the Netherlands' },
  { section: 'ESN Rotterdam', country: 'ESN the Netherlands' },
  { section: 'ESN Tilburg', country: 'ESN the Netherlands' },
  { section: 'ESN Twente', country: 'ESN the Netherlands' },
  { section: 'ESN Utrecht', country: 'ESN the Netherlands' },
  { section: 'ESN VU Amsterdam', country: 'ESN the Netherlands' },
  { section: 'ESN Wageningen', country: 'ESN the Netherlands' },
  { section: 'ESN KULTUR', country: 'ESN Turkey' },
  { section: 'ESN AKDENIZ', country: 'ESN Turkey' },
  { section: 'ESN ALTINBAS', country: 'ESN Turkey' },
  { section: 'ESN Anadolu', country: 'ESN Turkey' },
  { section: 'ESN Ankara University', country: 'ESN Turkey' },
  { section: 'ESN Arel', country: 'ESN Turkey' },
  { section: 'ESN AYBU', country: 'ESN Turkey' },
  { section: 'ESN Bahcesehir', country: 'ESN Turkey' },
  { section: 'ESN BASKENT', country: 'ESN Turkey' },
  { section: 'ESN Beykent University', country: 'ESN Turkey' },
  { section: 'ESN Bilkent', country: 'ESN Turkey' },
  { section: 'ESN Bogazici', country: 'ESN Turkey' },
  { section: 'ESN CAG', country: 'ESN Turkey' },
  { section: 'ESN Cerrahpasa', country: 'ESN Turkey' },
  { section: 'ESN Cukurova', country: 'ESN Turkey' },
  { section: 'ESN Dokuz Eylul University', country: 'ESN Turkey' },
  { section: 'ESN Ege University', country: 'ESN Turkey' },
  { section: 'ESN ESOGÜ', country: 'ESN Turkey' },
  { section: 'ESN ESTU', country: 'ESN Turkey' },
  { section: 'ESN Existanbul', country: 'ESN Turkey' },
  { section: 'ESN Gazi', country: 'ESN Turkey' },
  { section: 'ESN IBU', country: 'ESN Turkey' },
  { section: 'ESN ITU', country: 'ESN Turkey' },
  { section: 'ESN IUE', country: 'ESN Turkey' },
  { section: 'ESN Kadir Has', country: 'ESN Turkey' },
  { section: 'ESN Kocaeli', country: 'ESN Turkey' },
  { section: 'ESN Marmara', country: 'ESN Turkey' },
  { section: 'ESN METU', country: 'ESN Turkey' },
  { section: 'ESN MU', country: 'ESN Turkey' },
  { section: 'ESN NİSANTASİ', country: 'ESN Turkey' },
  { section: 'ESN Okan', country: 'ESN Turkey' },
  { section: 'ESN PAMUKKALE', country: 'ESN Turkey' },
  { section: 'ESN SAKARYA', country: 'ESN Turkey' },
  { section: 'ESN SDU', country: 'ESN Turkey' },
  { section: 'ESN Selcuk', country: 'ESN Turkey' },
  { section: 'ESN Trabzon', country: 'ESN Turkey' },
  { section: 'ESN TRAKYA', country: 'ESN Turkey' },
  { section: 'ESN Yasar', country: 'ESN Turkey' },
  { section: 'ESN Yeditepe', country: 'ESN Turkey' },
  { section: 'ESN YILDIZ', country: 'ESN Turkey' },
  { section: 'ESN-Samsun', country: 'ESN Turkey' },
  { section: 'ESN Aberdeen', country: 'ESN UK' },
  { section: 'ESN Aberystwyth', country: 'ESN UK' },
  { section: 'ESN Bristol', country: 'ESN UK' },
  { section: 'ESN City London', country: 'ESN UK' },
  { section: 'ESN Coventry', country: 'ESN UK' },
  { section: 'ESN Durham', country: 'ESN UK' },
  { section: 'ESN Edinburgh', country: 'ESN UK' },
  { section: 'ESN GCU', country: 'ESN UK' },
  { section: 'ESN Imperial', country: 'ESN UK' },
  { section: 'ESN Leeds', country: 'ESN UK' },
  { section: 'ESN Newcastle', country: 'ESN UK' },
  { section: 'ESN Oxford Brookes', country: 'ESN UK' },
  { section: 'ESN QMUL', country: 'ESN UK' },
  { section: 'ESN RGU', country: 'ESN UK' },
  { section: 'ESN Southampton', country: 'ESN UK' },
  { section: 'ESN Strathclyde', country: 'ESN UK' },
  { section: 'ESN UCLan', country: 'ESN UK' },
  { section: 'ESN UEA', country: 'ESN UK' },
  { section: 'ESN University of Wales', country: 'ESN UK' },
  { section: 'ESN UofG', country: 'ESN UK' },
  { section: 'ESN UWE', country: 'ESN UK' },
  { section: 'ESN Warwick', country: 'ESN UK' },
  { section: 'ESN Kyiv', country: 'ESN Ukraine' }
];

export const ESNCountries = Array.from(new Set(ESNSections.map(x => x.country)));
